import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'

export const useMobileFontOverrides = () => {
  const {experiments} = useExperiments()
  const disableMobileFontOverridesEnabled = experiments.enabled(ExperimentNames.DisableMobileFontOverrides)
  const {isEditorX: isStudio} = useEnvironment()

  const mobileFontOverridesEnabled = !disableMobileFontOverridesEnabled || !isStudio

  return {mobileFontOverridesEnabled}
}
